import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { motion } from "framer-motion";
import yo from '../images/1517002479941.jpeg';
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ExternalLinkIcon } from "@heroicons/react/outline"
import { ArrowRightIcon } from '@heroicons/react/solid';

const container = {
  hidden: { opacity: 1 },
  delay: .25,
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.35
    }
  }
};
const pic = {
  hidden: { scale: .95, opacity: 0 },
  visible: {
    transition: { duration: .3 },
    scale: 1,
    opacity: 1
  }
}
const headerItem = {
  hidden: { y: 5, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};
function IndexPage({ data }) {


  const { allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { nodes } = allMarkdownRemark


  const yearRecapImage = getImage(data.file)
  const TeslaImage = getImage(data.fileTesla)
  let yearRecapImageURL = data.file.childImageSharp.original.src
  let TeslaImageURL = data.fileTesla.childImageSharp.original.src
  return (
    <Layout>
      <Seo title="David Krasniy - Frontend Design &amp; Developement" />
      <motion.div className='flex flex-col justify-center items-start max-w-4xl border-gray-200 dark:border-gray-700 mx-auto py-12'

        initial={{ opacity: 0, y: 2 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: .3, delay: .01 }, }}
        viewport={{ once: true }}>

        <motion.ul
          className="w-full"
          variants={container}
          initial="hidden"
          animate="visible"
        >

          <div className="flex flex-col-reverse sm:flex-row items-start">
            <div className="flex flex-col w-full md:pr-8 ">

              <h1 className="font-bold text-3xl md:text-4xl tracking-tight mb-1 text-black dark:text-white text-center sm:text-left">David Krasniy</h1>
              <h2 className="text-gray-700 dark:text-gray-200 mb-3 text-center sm:text-left">Frontend Software Developer</h2>
              {/* <div className='mx-auto sm:m-0'>
                <span className="mb-3 inline-flex  items-center border border-blue-500 dark:border-gray-600 rounded  px-2 py-0.5 text-xs text-blue-600 dark:text-blue-200">
                  <svg className="mr-1.5 h-2 w-2 text-blue-500 animate-pulse	" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  Available for new projects
                </span></div> */}
              <p className="text-gray-400 dark:text-gray-400 mb-6 sm:mb-8   text-center sm:text-left">Building delightful user experiences.</p>

            </div>
            <motion.div variants={pic} className="shadow-lg rounded-full  ml-auto w-20 sm:w-32 relative mb-8 sm:mb-0 mr-auto">
              <img src={yo} className="rounded-full overflow-hidden" alt="David Krasniy portrait shot" />
            </motion.div>
          </div>

          <div className="flex items-center space-x-3 mx-auto justify-center sm:justify-start">
            <a href="#" target="_blank" rel="noreferrer"
              type="button"
              className="appearance-none relative group text-sm font-semibold text-gray-800 dark:text-gray-200  px-3  py-2 rounded-lg bg-white hover:bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700  focus:outline-none focus:ring-2 focus:ring-gray-400 focus:bg-gray-800  flex items-center"
            ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-300 transition" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>Resume
              <span className="hidden absolute transition opacity-0 group-hover:opacity-100 right-0 text-gray-500 font-medium text-xs" style={{ top: '110%' }}>(53kb)</span>
            </a>

            <a href="https://github.com/dkrasniy" target="_blank" rel="noreferrer"
              type="button"
              className="appearance-none  text-sm font-semibold text-gray-800 dark:text-gray-200   px-3  py-2 rounded-lg dark:bg-gray-800 bg-white hover:bg-gray-100  hover:bg-gray-200 dark:hover:bg-gray-700  focus:outline-none focus:ring-2 focus:ring-gray-400 focus:bg-gray-800 flex items-center"
            ><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-300 transition" viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" fill="current" /></svg>GitHub
            </a>

          </div>
          <div className="mt-10">
            <div className={`flex flex-wrap p-4 py-6 text-center bg-white dark:bg-gray-800 rounded-2xl dark:shadow`}>
              <div className='sm:order-2 flex items-center flex-col justify-center sm:pl-8 w-full sm:w-1/2'>
              <svg  className="h-3 w-full text-white mb-4" viewBox="0 0 342 35" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M0 .1a9.7 9.7 0 0 0 7 7h11l.5.1v27.6h6.8V7.3L26 7h11a9.8 9.8 0 0 0 7-7H0zm238.6 0h-6.8v34.8H263a9.7 9.7 0 0 0 6-6.8h-30.3V0zm-52.3 6.8c3.6-1 6.6-3.8 7.4-6.9l-38.1.1v20.6h31.1v7.2h-24.4a13.6 13.6 0 0 0-8.7 7h39.9v-21h-31.2v-7zm116.2 28h6.7v-14h24.6v14h6.7v-21h-38zM85.3 7h26a9.6 9.6 0 0 0 7.1-7H78.3a9.6 9.6 0 0 0 7 7m0 13.8h26a9.6 9.6 0 0 0 7.1-7H78.3a9.6 9.6 0 0 0 7 7m0 14.1h26a9.6 9.6 0 0 0 7.1-7H78.3a9.6 9.6 0 0 0 7 7M308.5 7h26a9.6 9.6 0 0 0 7-7h-40a9.6 9.6 0 0 0 7 7"></path></svg>
                <h1 className="font-bold text-xl md:text-2xl tracking-tight text-black dark:text-gray-200 text-left px-3">$1,000 OFF any new Tesla</h1>
                <p className='text-gray-600 dark:text-gray-300 py-3 mb-3 sm:mb-0'>
                  Use my referral link to receive $1,000 off the purchase of any new Tesla Vehicle! 
                </p>
                <a href="https://www.tesla.com/referral/anna476657"  target="_blank" className="relative block mt-4 bg-gray-100 hover:bg-gray-200 text-gray-700 w-full cursor-pointer transition duration-150 ease-in-out dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-200 focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-xl   inline-flex items-center justify-center">Use Referral <span className='text-gray-400 block ml-1'>ts.la/david76084</span> <ExternalLinkIcon className='w-4 h-4 absolute top-4 right-4'/>                 </a>
              <div className='flex my-4'><svg  viewBox="0 0 24 24"  className='flex-shrink-0  w-5 h-5' xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="6" fill="#fff"></circle><path fill="#12BB00" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2m4.78 8.284-5.491 5.492a.75.75 0 0 1-1.061 0L7.47 13.018a.75.75 0 1 1 1.06-1.061l2.228 2.229 4.962-4.962a.75.75 0 1 1 1.06 1.06"></path></svg>
          <p className='text-gray-400 text-sm'> Discount will be automatically applied by clicking the referral link above. Look for the green check mark to confirm. </p>  </div>  
              </div>
              {TeslaImage ? <div className="group w-full sm:w-1/2 md:order-1">
                <a href={TeslaImageURL} target="_blank" rel="noreferrer" alt={`Tesla Referral Code Link`} className="relative cursor-pointer">

                  <GatsbyImage image={TeslaImage} className="rounded-xl overflow-hidden shadow-xl" alt={'Tesla Referral Code Link'} />

                  {/* <span className=" absolute transition opacity-0 group-hover:opacity-100 right-0 bottom-0 text-gray-500 font-medium w-10 h-10 rounded-full flex items-center justify-center mr-2 mb-2" style={{ background: 'rgb(0 0 0 / 75%)' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 p-1 text-white" viewBox="0 0 512 512"><path fill="currentColor" d="M497.2 1.852C494.2 .6406 491.1 0 488 0h-143.1C330.8 0 320 10.75 320 24s10.75 24 24 24h86.06l-135 135c-9.375 9.375-9.375 24.56 0 33.94s24.56 9.375 33.94 0L464 81.94V168C464 181.3 474.8 192 488 192S512 181.2 512 167.1V24c0-3.133-.6406-6.25-1.855-9.18C507.7 8.957 503 4.285 497.2 1.852zM14.83 510.1C17.76 511.4 20.88 512 24 512h143.1C181.2 512 192 501.3 192 488S181.3 464 168 464H81.94l135-135c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L48 430.1V344C48 330.8 37.25 320 24 320S0 330.8 0 344V488c0 3.133 .6406 6.25 1.855 9.18C4.289 503 8.965 507.7 14.83 510.1z" /></svg></span> */}

                </a>
              </div> : null} 

            </div></div>



          <div className="mt-10">
            <div className={`flex flex-wrap p-4 py-6 text-center bg-white dark:bg-gray-900 border dark:border-gray-800 border-white rounded-2xl dark:shadow`}>
              <div className='sm:order-2 flex items-center flex-col justify-center sm:pl-8 w-full sm:w-1/2'>
                <h1 className="font-bold text-xl md:text-2xl tracking-tight text-black dark:text-gray-200 text-left px-3">2023 Recap 🎆</h1>
                <p className='text-gray-600 dark:text-gray-300 py-3 mb-3 sm:mb-0'>
                  A snapshot of my work from 2023. Wishing everyone a Happy New Year 2024! 🚀

                </p>
                <Link to="/contact" className="appearance-none hidden sm:block mt-4 bg-gray-100 hover:bg-gray-200 text-gray-700 w-full cursor-pointer transition duration-150 ease-in-out dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-200 focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-xl   inline-flex items-center justify-center" >Start a project</Link>

              </div>
              {yearRecapImage ? <div className="group w-full sm:w-1/2 md:order-1">
                <a href={yearRecapImageURL} target="_blank" rel="noreferrer" alt={`Year in Review 2022`} className="relative cursor-pointer">

                  <GatsbyImage image={yearRecapImage} className="rounded-xl overflow-hidden shadow-xl" alt={'Year in Review 2022'} />

                  {/* <span className=" absolute transition opacity-0 group-hover:opacity-100 right-0 bottom-0 text-gray-500 font-medium w-10 h-10 rounded-full flex items-center justify-center mr-2 mb-2" style={{ background: 'rgb(0 0 0 / 75%)' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 p-1 text-white" viewBox="0 0 512 512"><path fill="currentColor" d="M497.2 1.852C494.2 .6406 491.1 0 488 0h-143.1C330.8 0 320 10.75 320 24s10.75 24 24 24h86.06l-135 135c-9.375 9.375-9.375 24.56 0 33.94s24.56 9.375 33.94 0L464 81.94V168C464 181.3 474.8 192 488 192S512 181.2 512 167.1V24c0-3.133-.6406-6.25-1.855-9.18C507.7 8.957 503 4.285 497.2 1.852zM14.83 510.1C17.76 511.4 20.88 512 24 512h143.1C181.2 512 192 501.3 192 488S181.3 464 168 464H81.94l135-135c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L48 430.1V344C48 330.8 37.25 320 24 320S0 330.8 0 344V488c0 3.133 .6406 6.25 1.855 9.18C4.289 503 8.965 507.7 14.83 510.1z" /></svg></span> */}

                </a>
              </div> : null}

              <Link to="/contact" className="appearance-none block sm:hidden mt-4 bg-gray-100 hover:bg-gray-200 text-gray-700 w-full cursor-pointer transition duration-150 ease-in-out dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-200 focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-xl   inline-flex items-center justify-center" >Start a project</Link>

            </div></div>

          <div id="work" className='pt-10 -mt-10' />
          <motion.div variants={headerItem} className="mt-12">

            <h1 className="font-bold text-xl md:text-2xl tracking-tight mb-1 text-black dark:text-gray-200 text-left mb-6 px-3">My Work</h1>





            <div className="grid grid-cols-2 gap-8">
              {nodes.map((project, p) => {
                const {
                  title,
                  description,
                  featuredimage,
                  id,
                  slug,
                  company,
                  role,
                  preview,
                  previewText,
                  tools,
                  caseStudy,
                  featured
                } = project.frontmatter

                const featuredGatsbyImage = getImage(featuredimage)


                let imgURL = featuredimage.childImageSharp.original.src

                if (featured) {
                  return (<div key={id} className={`col-span-2 flex flex-wrap bg-white dark:bg-gray-800 px-8 sm:px-6 py-6 rounded-2xl dark:shadow`}>
                    <div className={`w-full sm:w-1/2 py-4 sm:px-4 flex flex-col justify-between ${p % 2 ? 'sm:order-2 sm:pl-12' : ''}`}>
                      <div>
                        <h3 className="font-semibold text-gray-800 dark:text-gray-200 text-lg">{title}</h3>
                        <p className="text-gray-500 dark:text-gray-400">{description}</p>
                      </div>
                      <div className="my-6 text-sm sm:pr-6">
                        {role ? <div className="mb-2"><p className="text-gray-500 dark:text-gray-300"><span className="block text-gray-700 dark:text-gray-500">My Role</span>{role && role.map((item, i) => `${i > 0 ? ', ' : ''}${item}`)}</p></div> : null}
                        {tools ? <div className="mb-2"><p className="text-gray-500 dark:text-gray-300"><span className="block text-gray-700 dark:text-gray-500">Tools</span>{tools && tools.map((item, i) => `${i > 0 ? ', ' : ''}${item}`)}</p></div> : null}
                        {company ? <div className="mb-2"><p className="text-gray-500 dark:text-gray-300"><span className="block text-gray-700 dark:text-gray-500">Client</span>{company}</p></div> : null}
                      </div>
                      <div className="flex items-center space-x-3">

                        {slug && caseStudy ? <Link to={slug}
                          type="button"
                          className="appearance-none text-xs font-semibold text-gray-800 dark:text-gray-200  inline-flex px-3 border border-gray-200 dark:border-gray-700 py-2 rounded-lg dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700  focus:outline-none focus:ring-2 dark:focus:ring-gray-400 dark:focus:bg-gray-800 focus:ring-gray-200 focus:bg-gray-200  transition-all flex items-center"
                        >Learn More </Link> : null}

                        {preview ? <a href={preview} target="_blank" rel="noreferrer"
                          type="button"
                          className="appearance-none text-xs font-semibold text-gray-800 dark:text-gray-200  inline-flex px-3 border border-gray-200 dark:border-gray-700 py-2 rounded-lg dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700  focus:outline-none focus:ring-2 dark:focus:ring-gray-400 dark:focus:bg-gray-800 focus:ring-gray-200 focus:bg-gray-200  transition-all flex items-center"
                        >{previewText ? previewText : "Preview"} <ExternalLinkIcon className="w-4 h-4 text-gray-500 ml-2" /> </a> : null}
                      </div>
                    </div>

                    {featuredimage ? <div className="group w-full sm:w-1/2">
                      <a href={imgURL} target="_blank" rel="noreferrer" alt={`Preview of ${title}`} className="relative cursor-pointer">

                        <GatsbyImage image={featuredGatsbyImage} className="rounded-lg" alt={title} />

                        {/* <span className=" absolute transition opacity-0 group-hover:opacity-100 right-0 bottom-0 text-gray-500 font-medium w-10 h-10 rounded-full flex items-center justify-center mr-2 mb-2" style={{ background: 'rgb(0 0 0 / 75%)' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 p-1 text-white" viewBox="0 0 512 512"><path fill="currentColor" d="M497.2 1.852C494.2 .6406 491.1 0 488 0h-143.1C330.8 0 320 10.75 320 24s10.75 24 24 24h86.06l-135 135c-9.375 9.375-9.375 24.56 0 33.94s24.56 9.375 33.94 0L464 81.94V168C464 181.3 474.8 192 488 192S512 181.2 512 167.1V24c0-3.133-.6406-6.25-1.855-9.18C507.7 8.957 503 4.285 497.2 1.852zM14.83 510.1C17.76 511.4 20.88 512 24 512h143.1C181.2 512 192 501.3 192 488S181.3 464 168 464H81.94l135-135c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L48 430.1V344C48 330.8 37.25 320 24 320S0 330.8 0 344V488c0 3.133 .6406 6.25 1.855 9.18C4.289 503 8.965 507.7 14.83 510.1z" /></svg></span> */}

                      </a>
                    </div> : null}


                  </div>)
                }

              })}
            </div>
            <div className=" mt-10">
              <div className={`p-4 text-center bg-white dark:bg-gray-800 rounded-2xl dark:shadow`}>
                {/* <span className="mb-2 inline-flex  items-center border border-blue-500 dark:border-gray-600 rounded  px-2 py-0.5 text-xs text-blue-600 dark:text-blue-200">
                  <svg className="mr-1.5 h-2 w-2 text-blue-500 animate-pulse	" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  Available for new projects
                </span> */}
                <Link to='/contact' className='justify-center block text-lg font-bold dark:text-white flex space-x-2 items-center'>Start a project  <ArrowRightIcon className='ml-2 w-5 h-5' /></Link>
              </div></div>


          </motion.div>

        </motion.ul>

      </motion.div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    file(name: {eq: "2022_YIR"}) { 
      id
      name
      childImageSharp {
        gatsbyImageData(width: 1200)
        original {
          src
        }
      } 
  }
      fileTesla:file(name: {eq: "Homepage-Model-3-Desktop-US"}) { 
      id
      name
      childImageSharp {
        gatsbyImageData(width: 1200)
        original {
          src
        }
      } 
  }
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "portfolio-page"}}}
      sort: {fields: frontmatter___id, order: DESC}
    ) {
      nodes {
        frontmatter {
          title
          description
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 1200)
              original {
                src
              }
            }
          }
          id
          slug
          company
          type 
          role
          caseStudy 
          preview
          previewText
          tools
          featured
        }
      }
    }
  }
`